<template>
  <v-card-text class="pb-0">
    <p class="display-1 text--primary" style="text-align: center;">
      Complimentary Details
    </p>

    <v-radio-group v-model="formData.tdComplimentary" label="TD Complimentary?">
      <v-radio
        label="Yes"
        value="Yes"
      ></v-radio>
      <v-radio
        label="No"
        value="No"
      ></v-radio>
    </v-radio-group>

    <v-radio-group v-model="formData.driverComplimentary" label="Driver Complimentary?">
      <v-radio
        label="Yes"
        value="Yes"
      ></v-radio>
      <v-radio
        label="No"
        value="No"
      ></v-radio>
    </v-radio-group>

    <div style="display:flex;justify-content:flex-end;z-index:1;margin-bottom:-25px;">
      <v-tooltip bottom>
        <template v-slot:activator="{ on, attrs }">
          <v-icon color="primary" dark v-bind="attrs" v-on="on" style="z-index:99">
            mdi-information-outline
          </v-icon>
        </template>
        <span>
          Ex: 1 chaperone required per 10 students. Required chaperones are complimentary.
        </span>
      </v-tooltip>
    </div>
    <v-textarea
      name="Group-related complimentary details"
      filled
      counter
      maxlength='255'
      label="Group-related complimentary details"
      auto-grow
      v-model="formData.complimentaryDetails"
    ></v-textarea>

    <v-combobox
      v-model="formData.complimentaryType"
      :items="complimentaryTypeOptions"
      label="Complimentary Type"
    ></v-combobox>

    <div style="display: flex; justify-content: flex-end; margin-bottom: -25px; margin-top: 25px;">
      <v-tooltip bottom>
        <template v-slot:activator="{ on, attrs }">
          <v-icon color="primary" dark v-bind="attrs" v-on="on">mdi-information-outline</v-icon>
        </template>
        <span>Ex: For a 1:20 Comp Ratio, Enter 20 into this box.</span>
      </v-tooltip>
    </div>
    <v-text-field v-model="formData.complimentaryRatio" label="Complimentary Ratio"></v-text-field>

    <div style="display:flex;justify-content:flex-end;z-index:1;margin-bottom:-25px;">
      <v-tooltip bottom>
        <template v-slot:activator="{ on, attrs }">
          <v-icon color="primary" dark v-bind="attrs" v-on="on" style="z-index:99">
            mdi-information-outline
          </v-icon>
        </template>
        <span>
          Place any notes for the GTO Team here, including if this pricing needs restricted access.
          This will be for internal GTO use only and will not be seen by users of the GTO system.
        </span>
      </v-tooltip>
    </div>
    <v-textarea
      name="Notes for GTO Internal Team"
      filled
      counter
      maxlength='355'
      label="Notes for GTO Internal Team"
      auto-grow
      v-model="formData.internalNotes"
    ></v-textarea>

  </v-card-text>
</template>

<script>
export default {
  name: 'CompDetails',
  props: {
    parentData: {
      type: Object,
    },
  },
  data() {
    return {
      formData: {
        tdComplimentary: this.parentData.tdComplimentary || '',
        driverComplimentary: this.parentData.driverComplimentary || '',
        complimentaryDetails: this.parentData.complimentaryDetails || '',
        complimentaryType: this.parentData.complimentaryType || [],
        complimentaryRatio: this.parentData.complimentaryRatio || '',
        internalNotes: this.parentData.internalNotes || '',
      },
      complimentaryTypeOptions: [
        'Ratio',
        'Flat',
        'Per Motorcoach',
      ],
    };
  },
  watch: {
    formData: {
      handler() {
        this.$emit('child-data', this.formData);
      },
      deep: true,
    },
  },
};
</script>
