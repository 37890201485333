var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('v-card-text',{staticClass:"pb-0"},[_c('p',{staticClass:"display-1 text--primary",staticStyle:{"text-align":"center"}},[_vm._v(" Complimentary Details ")]),_c('v-radio-group',{attrs:{"label":"TD Complimentary?"},model:{value:(_vm.formData.tdComplimentary),callback:function ($$v) {_vm.$set(_vm.formData, "tdComplimentary", $$v)},expression:"formData.tdComplimentary"}},[_c('v-radio',{attrs:{"label":"Yes","value":"Yes"}}),_c('v-radio',{attrs:{"label":"No","value":"No"}})],1),_c('v-radio-group',{attrs:{"label":"Driver Complimentary?"},model:{value:(_vm.formData.driverComplimentary),callback:function ($$v) {_vm.$set(_vm.formData, "driverComplimentary", $$v)},expression:"formData.driverComplimentary"}},[_c('v-radio',{attrs:{"label":"Yes","value":"Yes"}}),_c('v-radio',{attrs:{"label":"No","value":"No"}})],1),_c('div',{staticStyle:{"display":"flex","justify-content":"flex-end","z-index":"1","margin-bottom":"-25px"}},[_c('v-tooltip',{attrs:{"bottom":""},scopedSlots:_vm._u([{key:"activator",fn:function(ref){
var on = ref.on;
var attrs = ref.attrs;
return [_c('v-icon',_vm._g(_vm._b({staticStyle:{"z-index":"99"},attrs:{"color":"primary","dark":""}},'v-icon',attrs,false),on),[_vm._v(" mdi-information-outline ")])]}}])},[_c('span',[_vm._v(" Ex: 1 chaperone required per 10 students. Required chaperones are complimentary. ")])])],1),_c('v-textarea',{attrs:{"name":"Group-related complimentary details","filled":"","counter":"","maxlength":"255","label":"Group-related complimentary details","auto-grow":""},model:{value:(_vm.formData.complimentaryDetails),callback:function ($$v) {_vm.$set(_vm.formData, "complimentaryDetails", $$v)},expression:"formData.complimentaryDetails"}}),_c('v-combobox',{attrs:{"items":_vm.complimentaryTypeOptions,"label":"Complimentary Type"},model:{value:(_vm.formData.complimentaryType),callback:function ($$v) {_vm.$set(_vm.formData, "complimentaryType", $$v)},expression:"formData.complimentaryType"}}),_c('div',{staticStyle:{"display":"flex","justify-content":"flex-end","margin-bottom":"-25px","margin-top":"25px"}},[_c('v-tooltip',{attrs:{"bottom":""},scopedSlots:_vm._u([{key:"activator",fn:function(ref){
var on = ref.on;
var attrs = ref.attrs;
return [_c('v-icon',_vm._g(_vm._b({attrs:{"color":"primary","dark":""}},'v-icon',attrs,false),on),[_vm._v("mdi-information-outline")])]}}])},[_c('span',[_vm._v("Ex: For a 1:20 Comp Ratio, Enter 20 into this box.")])])],1),_c('v-text-field',{attrs:{"label":"Complimentary Ratio"},model:{value:(_vm.formData.complimentaryRatio),callback:function ($$v) {_vm.$set(_vm.formData, "complimentaryRatio", $$v)},expression:"formData.complimentaryRatio"}}),_c('div',{staticStyle:{"display":"flex","justify-content":"flex-end","z-index":"1","margin-bottom":"-25px"}},[_c('v-tooltip',{attrs:{"bottom":""},scopedSlots:_vm._u([{key:"activator",fn:function(ref){
var on = ref.on;
var attrs = ref.attrs;
return [_c('v-icon',_vm._g(_vm._b({staticStyle:{"z-index":"99"},attrs:{"color":"primary","dark":""}},'v-icon',attrs,false),on),[_vm._v(" mdi-information-outline ")])]}}])},[_c('span',[_vm._v(" Place any notes for the GTO Team here, including if this pricing needs restricted access. This will be for internal GTO use only and will not be seen by users of the GTO system. ")])])],1),_c('v-textarea',{attrs:{"name":"Notes for GTO Internal Team","filled":"","counter":"","maxlength":"355","label":"Notes for GTO Internal Team","auto-grow":""},model:{value:(_vm.formData.internalNotes),callback:function ($$v) {_vm.$set(_vm.formData, "internalNotes", $$v)},expression:"formData.internalNotes"}})],1)}
var staticRenderFns = []

export { render, staticRenderFns }